
















































































































































































































































































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import { i18n } from "@/locales/i18n";
import { CartHistoryQueryRow } from "@/core/dataTypes/types";

export default defineComponent({
  name: "PurchasesTableMobile",
  components: {},
  setup() {
    onMounted(() => {
      nextTick(() => {
        const tableTag = document.getElementsByTagName("table")[0];
        tableTag.setAttribute("role", "none");
        const tbodyTag = document.getElementsByTagName("tbody")[0];
        tbodyTag.setAttribute("role", "list");
      });
    });

    const items = computed(stickerFacade.cartPurchaseHistory);
    const cartPurchaseHistoryLoading = computed(
      stickerFacade.cartPurchaseHistoryLoading
    );
    const headers = ref([
      {
        text: i18n.tc("MY_PURCHASES_PAGE.TABLE_HEADER.INVOICE_NUMBER"),
        value: "invoiceId",
      },
      {
        text: i18n.tc("MY_PURCHASES_PAGE.TABLE_HEADER.PURCHASE_DATE"),
        value: "purchaseDate",
      },
      {
        text: i18n.tc("MY_PURCHASES_PAGE.TABLE_HEADER.STICKERS_COUNT"),
        value: "cartElementsCount",
        align: "right",
      },
      {
        text: i18n.tc("MY_PURCHASES_PAGE.TABLE_HEADER.TOTAL"),
        value: "cost",
        align: "right",
      },
      {
        text: "",
        value: "invoiceDownload",
        width: "30%",
      },
    ]);

    const getYearsFrom2015 = (): string[] => {
      const years = [];
      years.push("----");
      for (let i = 2013; i <= new Date().getFullYear(); i++) {
        years.push(i.toString());
      }
      return years;
    };

    const invoiceYearItems = ref(getYearsFrom2015());

    const expanded = ref([]);
    const itemsPerPage = ref(10);
    const pageCount = ref(0);
    const page = ref(1);
    const firstSubmit = ref(false);

    const filters = reactive({
      invoiceNumber: "",
      plateNumber: "",
      invoiceYear: invoiceYearItems.value[0],
    });

    const paginationParams = reactive({
      paginationParams: {
        rows: 100,
        page: 1,
      },
    });

    const onInvoiceDownload = (invoiceId: string) => {
      const index = items.value?.findIndex(
        (item: CartHistoryQueryRow) => invoiceId === item.invoiceId
      ) as number;
      stickerFacade.getInvoice({ invoiceId: invoiceId, index: index });
    };

    const onDocumentDownload = (
      invoiceId: string,
      requestId: string,
      cartIndex: number
    ) => {
      const index = items.value?.findIndex(
        (item: CartHistoryQueryRow) => invoiceId === item.invoiceId
      ) as number;
      stickerFacade.getDocument({
        requestId: requestId,
        index: index,
        cartIndex: cartIndex,
      });
    };

    return {
      items,
      cartPurchaseHistoryLoading,
      headers,
      expanded,
      itemsPerPage,
      pageCount,
      page,
      firstSubmit,
      filters,
      paginationParams,
      onInvoiceDownload,
      onDocumentDownload,
    };
  },
});
