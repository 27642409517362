


























































































































































































































































































































































import {
  defineComponent,
  ref,
  reactive,
  computed,
  onMounted,
  SetupContext,
} from "@vue/composition-api";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import { EStickerTypeValue } from "@/core/dataTypes/enum";
import TextInput from "@/components/forms/input/TextInput.vue";
import PurchasesTableMobile from "@/components/purchasesTableMobile/purchasesTableMobile.vue";
import { validationRegExp } from "@/core/utils/validation/validationRegExp";
import { CartHistoryQueryParams } from "@/core/dataTypes/types";
import { CartHistoryQueryRow } from "@/core/dataTypes/types";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "MyPurchasesPage",
  components: { TextInput, PurchasesTableMobile },
  setup(
    props,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { refs, root }: Omit<SetupContext, "refs"> & { [key: string]: any }
  ) {
    const items = computed(stickerFacade.cartPurchaseHistory);
    const cartPurchaseHistoryLoading = computed(
      stickerFacade.cartPurchaseHistoryLoading
    );
    const getInvoiceLoading = computed(stickerFacade.getInvoiceLoading);
    const currentDownloadInvoice = ref(0);
    const headers = ref([
      {
        text: i18n.tc("MY_PURCHASES_PAGE.TABLE_HEADER.INVOICE_NUMBER"),
        value: "invoiceId",
      },
      {
        text: i18n.tc("MY_PURCHASES_PAGE.TABLE_HEADER.PURCHASE_DATE"),
        value: "purchaseDate",
      },
      {
        text: i18n.tc("MY_PURCHASES_PAGE.TABLE_HEADER.STICKERS_COUNT"),
        value: "cartElementsCount",
        align: "right",
      },
      {
        text: i18n.tc("MY_PURCHASES_PAGE.TABLE_HEADER.TOTAL"),
        value: "cost",
        align: "right",
      },
      {
        text: "",
        value: "invoiceDownload",
        width: "30%",
      },
    ]);

    const getYearsFrom2015 = (): string[] => {
      const years = [];
      years.push("----");
      for (let i = new Date().getFullYear(); i >= 2016; i--) {
        years.push(i.toString());
      }
      return years;
    };

    const invoiceYearItems = ref(getYearsFrom2015());

    const expanded = ref([]);
    const itemsPerPage = ref(10);
    const pageCount = ref(0);
    const page = ref(1);
    const firstSubmit = ref(false);

    const filters = reactive({
      invoiceNumber: "",
      plateNumber: "",
      invoiceYear: invoiceYearItems.value[0],
    });

    const paginationParams = reactive({
      paginationParams: {
        rows: 100,
        page: 1,
      },
    });
    const getCartPurchaseHistory = (queryParams?: CartHistoryQueryParams) => {
      stickerFacade.getCartPurchaseHistory({
        queryParams: {
          invoiceNumber: null,
          plateNumber: null,
          invoiceYear: null,
          ...queryParams,
        },
        ...paginationParams,
      });
    };

    const onSearch = () => {
      firstSubmit.value = true;
      const invoiceNumberField =
        refs.filterForm.fields[
          i18n.tc("MY_PURCHASES_PAGE.TABLE_HEADER.INVOICE_NUMBER")
        ];
      const plateNumberField =
        refs.filterForm.fields[
          i18n.tc("MY_PURCHASES_PAGE.FILTERS.INVOICE_NUMBER_LABEL")
        ];
      const queryParams = {
        invoiceNumber:
          !!filters.invoiceNumber.length && invoiceNumberField.valid
            ? filters.invoiceNumber
            : null,
        plateNumber:
          !!filters.plateNumber.length && plateNumberField.valid
            ? filters.plateNumber
            : null,
        invoiceYear:
          filters.invoiceYear !== invoiceYearItems.value[0]
            ? filters.invoiceYear
            : null,
      };
      getCartPurchaseHistory(queryParams);
    };

    const onInvoiceDownload = (invoiceId: string) => {
      const index = items.value?.findIndex(
        (item: CartHistoryQueryRow) => invoiceId === item.invoiceId
      ) as number;
      stickerFacade.getInvoice({ invoiceId: invoiceId, index: index });
    };

    const onDocumentDownload = (
      invoiceId: string,
      requestId: string,
      cartIndex: number
    ) => {
      const index = items.value?.findIndex(
        (item: CartHistoryQueryRow) => invoiceId === item.invoiceId
      ) as number;
      stickerFacade.getDocument({
        requestId: requestId,
        index: index,
        cartIndex: cartIndex,
      });
    };

    const isDesktopBreakpoint = computed(() => {
      const breakpointName = root.$vuetify.breakpoint.name;
      if (
        breakpointName === "md" ||
        breakpointName === "lg" ||
        breakpointName === "xl"
      ) {
        refs.myPurchasesTable;
        return true;
      } else {
        return false;
      }
    });

    onMounted(() => {
      getCartPurchaseHistory();
    });
    stickerFacade.resetCartPurchasedHistory();

    return {
      firstSubmit,
      filters,
      itemsPerPage,
      pageCount,
      page,
      items,
      headers,
      expanded,
      EStickerTypeValue,
      invoiceYearItems,
      cartPurchaseHistoryLoading,
      getInvoiceLoading,
      validationRegExp,
      onSearch,
      onInvoiceDownload,
      onDocumentDownload,
      currentDownloadInvoice,
      isDesktopBreakpoint,
    };
  },
  metaInfo() {
    return {
      titleTemplate: `${i18n.tc("MY_PURCHASES_PAGE.PAGE_TITLE")} | %s`,
    };
  },
});
